import React from "react"
import { Link } from "gatsby"

const ClasslinkOffer = () => {
  return (
    <>
      <section className="mfl-accordions-section section">
        <div className="container">
          <div className="row mfl-accordions-row">
            <div className="col-xs-12">
              <h3 className="title">MathFactLab Exclusive ClassLink Pilot Offer</h3>
              <div className="content-24 bold-text">
                MathFactLab is a teacher-created, strategy-based, math fact fluency website for students in first grade
                and up. We offer a bridge to fact fluency by providing students with individualized practice that
                reinforces the strategies that they have learned in the classroom using a range of models that they are
                familiar with.
              </div>
              <div className="content-24 bold-text">
                We are happy to offer ClassLink schools and districts free pilot access for the remainder of the
                2024-2025 school year. This can include as many schools, teachers and students as you would like.
              </div>

              <div className="content-20">
                If interested, please complete our{" "}
                <a href="https://mathfactlab.fillout.com/t/8hccismPaVus" target="_blank" rel="noreferrer">
                  ClassLink Pilot Request Form.
                </a>
              </div>

              <div className="content-20">Want to learn more first:</div>
              <ul className="" style={{ marginLeft: "33px", listStyleType: "circle" }}>
                <li>
                  Visit our website:{" "}
                  <a href="http://www.mathfactlab.com" target="_blank" rel="noreferrer">
                    www.mathfactlab.com
                  </a>
                </li>
                <li>
                  <a href="https://vimeo.com/763997138?share=copy" target="_blank" rel="noreferrer">
                    Watch our Demo Video
                  </a>
                </li>

                <li>
                  <Link className="mfl-foo-link" to="/classroom/" title="MathFactLab Pricing" rel="canonical">
                    Read our guiding principles
                  </Link>
                </li>
                <li>
                  <a href="https://vimeo.com/800363534?share=copy" target="_blank" rel="noreferrer">
                    Watch the MathFactLab Student Experience tutorial video
                  </a>
                </li>
                <li>
                  <a
                    href="https://mathfactlab.helpscoutdocs.com/article/122-mathfactlab-diy-professional-development-training"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Explore our DIY PD Guide
                  </a>
                </li>
                <li>
                  <a href="https://calendly.com/mathfactlab/30min" target="_blank" rel="noreferrer">
                    Schedule a meeting with our founder
                  </a>
                </li>
                <li>
                  <a
                    href="https://mathfactlab.helpscoutdocs.com/article/89-testimonials-from-mathfactlab-users"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read MathFactLab testimonials
                  </a>
                </li>
                <li>
                  <a href="https://vimeo.com/854804900?share=copy#t=0" target="_blank" rel="noreferrer">
                    Learn about our differentiation options
                  </a>
                </li>
              </ul>

              <br />
              <div className="content-24 bold-text">This offer is only available to ClassLink Customers.</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ClasslinkOffer
